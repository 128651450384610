//! TEMP SO BUILD/DEPLOY WON'T FAIL
/* eslint-disable */

import { Dispatch } from 'redux';

import { setVotee } from '../../store/main.slice';
import { addMessage } from '../../store/messages.slice';
import {
  /*getUsers,*/ setUsers, addUser, removeUser, removeTypingUser, /*setOnlineUsersById,*/ setTypingUser,
} from '../../store/users.slice';
import { setGroups } from '../../store/groups.slice';
import { preLogin, login, setLoggedInUserId } from '../../store/auth.slice';

import {
  //! initQuiz, 
  setAnswer, removeUserAnswers,
  //!
  /*setLoading, setLoadingComplete,*/ setQuiz,
} from '../../store/quiz.slice';
import { User, Group, Quiz, Message, RootState } from '../utilities/types';

import { initSocketListeners } from './socketListeners';

import { debug } from '../utilities/debug';

interface OtherStoreMiddlewareParams {
  dispatch: Dispatch,
  getState: () => RootState,
};

export default function otherStoreMiddleware() {
  return (params: OtherStoreMiddlewareParams) => (next: any) => async (action: any) => {
    const { dispatch, getState } = params;
    const { type, payload } = action;

    // Set 'votee' from 'voteeId' when main/setVoteeId or main/setVoteeIdSilently get dispatched
    switch (type) {
      case 'main/setVoteeId':
      case 'main/setVoteeIdSilently':
        const voteeId = payload;
        const users = getState().usersState.users;
        const newVotee = users.find(user => user.id === voteeId);
        dispatch(setVotee(newVotee));
        break;
    }

    return next(action);
  };
};
