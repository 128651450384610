import Lottie from 'react-lottie';
import { User, Group } from '../app/utilities/types';

import './UserAvatar.css';

// TODO COULD THIS BE LOADED ON DEMAND DYNAMICALLY?
import emojiKissingHeart from '../lottie/users/default/emoji-kissing-heart.json';
import emojiSmileyCat from '../lottie/users/default/emoji-smiley-cat.json';

import { CountdownCircleTimer } from "react-countdown-circle-timer";

// TODO COULD BE USED FOR GROUP AVATARS ALSO
export interface Avatar {
  name: string,
  image?: string,
  lottie?: any,
  tags: string[],
}

/**
 * Ref: https://app.lottiefiles.com/
 * Ref: https://lottiefiles.com/
 * Ref: https://googlefonts.github.io/noto-emoji-animation/
 */
export const userAvatars: Record<string, Avatar> = {
  "DEFAULT": {
    name: "Default",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["emoji", "human"],
  },
  "EMOJI_1": {
    name: "Kissing heart",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["emoji", "human"],
  },
  "EMOJI_2": {
    name: "Smiley cat",
    image: "default/emoji-smiley-cat.json",
    lottie: emojiSmileyCat,
    tags: ["emoji", "animal"],
  },
  "HP_1": {
    name: "Harry Potter",
    image: "harry-potter/harry-potter.jpg",
    tags: ["harry potter", "gryffindor", "human", "half blood", "male"],
    // TODO Make keys an 'and' relation, so:
    // TODO gryffindor + male will filter all males from house Gryffindor, whereas
    // TODO gryffindor + male + human will filter all males from house Gryffindor that are human only
    // TODO gryffindor + half blood + male + human will filter all males from house Gryffindor that are human or half blood
    // TODO Also maybe these should all be IDs?
    // tags: [{
    //     "harry potter": ["gryffindor", "half blood"], // Group theme
    //     "gender": ["male"],
    //     "species": ["human"],
    // }],
  },
  "HP_2": {
    name: "Hermione Granger",
    image: "harry-potter/hermione-granger.jpg",
    tags: ["harry potter", "gryffindor", "human", "muggle", "female"],
  },
  "HP_3": {
    name: "Ronald Weasley",
    image: "harry-potter/ronald-weasley.jpg",
    tags: ["harry potter", "gryffindor", "human", "muggle", "male"],
  },
  "HP_4": {
    name: "Rubeus Hagrid",
    image: "harry-potter/hagrid.jpg",
    tags: ["harry potter", "gryffindor", "human", "giant", "male"],
  },
  "HP_5": {
    name: "Professor Severus Snape",
    image: "harry-potter/professor-snape.jpg",
    tags: ["harry potter", "slytherin", "human", "half blood", "male"],
  },
  "HP_6": {
    name: "Dobby",
    image: "harry-potter/dobby.jpg",
    tags: ["harry potter", "hufflepuff", "elf", "male"],
  },
  "HP_6b": {
    name: "Dobby DEUX",
    image: "default/emoji-kissing-heart.json",
    lottie: emojiKissingHeart,
    tags: ["harry potter", "hufflepuff", "elf", "male"],
  },
  "HP_7": {
    name: "Dobby 2",
    image: "harry-potter/dobby.jpg",
    tags: ["harry potter", "ravenclaw", "elf", "male"],
  },
};

export interface UserAvatarProps {
  key: string,
  avatarId?: string,
  name?: string,
  hasFocus?: boolean,
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  style?: object,
  children?: React.ReactNode,
// TEMP HACK TO SHOW GROUP AVATAR
group?: Group,
percentageVotes?: number,
}

const UserAvatar: React.FC<UserAvatarProps> = ({key, avatarId, name, hasFocus = false, onClick, style, children, group, percentageVotes}) => {
  const {
    image,
    lottie,
  } = userAvatars[avatarId ?? "DEFAULT"];

  return (
    <div
      key={key}
      className="user-avatar"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        margin: 5,
        // border: id === user.avatarId ? "1px dashed currentColor" : "none",
// background: "red",
flex: "1 1 0",
        ...style,
      }}
    >
      <div
        data-avatarid={avatarId}
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          // margin: 5,
          ...style,
//position: "relative",
//top: group ? 20 : 5,
        }}
      >
{/* TODO SHOW EXTRA INFO BEFORE AVATAR AS 'SLOT' (SUCH AS PERCENTAGE VOTES) */}
{/* <div style={{fontSize: "1.4em", fontWeight: "bold"}}>50 %</div> */}

{/* TEMP HACK TO SHOW GROUP AVATAR */}
<div style={{position: "relative"}}>
{!!percentageVotes &&
  <div
    className="percentage-votes"
    style={{
      fontSize: '.4em',
      marginLeft: 10,
      //? position: 'relative',
      //? top: -6,
      position: "absolute",
      top: group ? -20 : -5,
      left: group ? -24 : -15,
      zIndex: group ? 10000 : "inherit",
    }}
  >
    <CountdownCircleTimer
      key={percentageVotes}
      isPlaying={false}
      duration={100}
      initialRemainingTime={100 - percentageVotes}
      colors="#000000"
      size={group ? 116 : 90}
      strokeLinecap="square"
      strokeWidth={8}
    >{
      ({ remainingTime, elapsedTime }) => {
        return (percentageVotes > 0 &&
          <div className="timer" style={{fontSize: "1.2rem", fontWeight: "bold", position: "relative", top: -65}}>
            <div className="value">{100 - remainingTime} %</div>
          </div>
        );
      }
    }
    </CountdownCircleTimer>
  </div>
}

      {group &&
              <img
                src={group.image ?? "/assets/images/groups/default.jpg"}
                alt={`Groupe ${group.name}`}
                className="bounce-in"
                style={{
//flex: 0,
                  // margin: 5,
                  borderRadius: 16,
                  // minWidth: 100,
                  // minHeight: 100,
                  // maxWidth: 250,
                  // width: 250,
                  // height: 250,
                  //! width: "100%",
                  //! height: "100%",
                  width: 100,
                  height: 80,
                  // flex: "0 1",
                  // border: "1px dashed red",
                }}
              />

      }

        {lottie &&
!group &&
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: lottie,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={80}
            width={80}
            style={{
              // border: hasFocus ? "4px solid rgb(230 140 63 / 80%)" : "none",
              border: hasFocus ? "4px solid rgb(230 140 63 / 80%)" : "4px solid transparent",
              borderRadius: 10,
              overflow: "visible",
            }}
          />
        }

        {image && !lottie &&
!group &&
          <img
            src={`/assets/images/users/${image}`}
            alt={`Utilisateur ${name}`}
            className="bounce-in"
            style={{
              width: 80,
              height: 80,
              borderRadius: 100,
              boxShadow: hasFocus ? "0px 0px 0px 4px rgb(230 140 63 / 80%)" : "0px 0px 0px 3px rgb(0 0 0 / 80%)",
              padding: 1,
              margin: 2,
            }}
          />
        }
</div>

        {children}

        <div style={{margin: 5, fontWeight: "normal", whiteSpace: "nowrap", background: "#333", color: "#eee", borderRadius: 8, padding: "2px 5px"}}>
          {name}
        </div>
      </div>
    </div>
  );
};

export default UserAvatar;
