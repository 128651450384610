//! TEMP!!! 'SEE BELOW)
import { useEffect, useRef } from 'react';

import {
    IonApp,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCard,
    IonContent,
    IonFooter,
    IonMenu,
    IonList,
    IonListHeader,
    IonMenuToggle,
    IonIcon,
    IonLabel,
    IonPage,
    IonSplitPane,
    IonButtons,
    IonButton,
    IonItem,
    IonTabBar,
    IonTabs,
    IonTabButton,
    IonRouterOutlet,
} from '@ionic/react';
  
import { home, menu } from 'ionicons/icons';

import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { AdminSidebar } from '../components/AdminSidebar';
import { VoteCountdown } from '../components/VoteCountdown';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../app/utilities/types';
import { logout } from '../store/auth.slice';

import { User, Group, Quiz, Question } from '../app/utilities/types';

function countQuestionsVotedFor(quiz: Quiz, user: User) {
  return quiz.questions.reduce(
    (numQuestionsVotedOn: number, question: Question) => numQuestionsVotedOn +
      (question.answers.some(answer => answer.userId === user.id) ? 1 : 0),
    0,
  );
}

//! const Page: React.FC = ({children}: any) => {
const Page = ({children}: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const contentRef = useRef<HTMLIonContentElement>(null);

    const { currentUser, hasValidRoomCode, isAuthenticated } = useSelector((state: RootState) => state.authState);

    const { users, typingUsers } = useSelector(
      (state: RootState) => state.usersState
    );
  
    const { groups } = useSelector(
      (state: RootState) => state.groupsState
    );
  
    const { quiz } = useSelector(
      (state: RootState) => state.quizState
    );
  
    const { messages } = useSelector(
      (state: RootState) => state.messagesState
    );
  
  const { votee, voteeId, stopVoting, scrollToPos, scrollToDuration } = useSelector(
    (state: RootState) => state.mainState
  );

  useEffect(() => {
    if (scrollToPos) {
      console.log(`Page effect: scrolling to scrollToPos=${JSON.stringify(scrollToPos)}...`);
      const [x, y] = scrollToPos;
      //! contentRef.current?.scrollToTop(y);
      const speed = scrollToDuration || 0;
      contentRef.current?.scrollToPoint(null, y, speed);
    }
  }, [scrollToPos, scrollToDuration]);

// TODO MOVE THIS TO STATE? OR UTILS? OR HOOKS? (SEE VoteArea.tsx ALSO)
    const isVoter = currentUser?.id !== voteeId && (!currentUser?.groupId || currentUser.groupId !== votee?.groupId);

// TODO MOVE THIS TO STATE? OR UTILS? OR HOOKS? (SEE VoteArea.tsx ALSO)
    const hasVotedForAllQuestions = isVoter && !!quiz && !!currentUser && countQuestionsVotedFor(quiz, currentUser) === quiz.questions.length;

    const handleLogout = (event: any) => {
        // Log out session
// TODO TEMP DO NOT REMOVE THIS FROM LOCAL SESSION IMMEDIATELY SO CAN TEST USER BEING ABLE TO LOG BACK IN AND RESUME THEIR PREVIOUS USER (WITH SESSION ID RENEWED)
        //? localStorage.removeItem('user');

        dispatch(logout());

        history.replace('/');
    };

    // Return to login if no longer authenticated (was logged out/disconnected with automatic reconnection)
    useEffect(() => {
      if (!isAuthenticated) {
        history.replace('/');
      }
  }, [isAuthenticated, history]);

    if (!hasValidRoomCode || !currentUser || (currentUser?.role === "admin" && !currentUser?.isPlaying)) {
      return (
        <IonPage>
                <div className="ion-page" id="main-content">
                  <Header onLogout={handleLogout}>
                    <div className="content-title">
                      {quiz?.title}
                      {/* <sup><span style={{fontSize: '1rem', justifySelf: 'flex-end'}}>
                        {timeElapsed >= voteEndDuration - voteWarningDuration &&
                          `Attention, il vous reste ${voteWarningDuration - (timeElapsed - (voteEndDuration - voteWarningDuration))} secondes pour voter !` ||
                          timeElapsed}
                      </span></sup> */}
                    </div>
                    <div className="content-countdown">
                      {(quiz && voteeId && (!stopVoting || (isVoter && !hasVotedForAllQuestions))) &&
                        <VoteCountdown
                          isVoter={isVoter}
                          hasVotedForAllQuestions={hasVotedForAllQuestions}
                          voteeId={voteeId}
                          voteStartTime={quiz.startTime}
                        />
                      }
                    </div>
                  </Header>

                  <IonContent ref={contentRef} className="ion-padding">
                    {children}
                  </IonContent>
                </div>
        </IonPage>
      );
    }

    return (
        <IonPage>
            {/* <IonSplitPane when="lg" contentId="ion-router-outlet"> */}
            <IonSplitPane when="lg" contentId="main-content">
                {/* <IonMenu contentId="main-content"> */}
                {/* <IonMenu contentId="ion-router-outlet"> */}
                <IonMenu contentId="main-content">
                    <IonHeader>
                        {/* <IonToolbar color="primary"> */}
                        <IonToolbar color="medium">
                            <IonTitle>Informations de Scratch---{JSON.stringify(currentUser)}</IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <IonList>
                            {/* <IonListHeader>Naviguer</IonListHeader> */}
                            <IonMenuToggle autoHide={false}>
                                {/* <IonItem button>
                                    <IonIcon slot="start" icon={home}></IonIcon>
                                    <IonLabel>Accueil</IonLabel>
                                </IonItem> */}



                            </IonMenuToggle>
                                <IonItem>
        {/* <IonCard> */}
          {/* users={usersWithOnlineData} */}

        {currentUser?.role === "admin" &&
          <AdminSidebar
            quiz={quiz}
            users={users}
            groups={groups}
            currentUser={currentUser}
            voteeId={voteeId}
            typingUsers={typingUsers}
            infoContext="sidebar"
          />
        }
        {currentUser && currentUser.role !== "admin" &&
          <Sidebar
            quiz={quiz}
            users={users}
            groups={groups}
            currentUser={currentUser}
            voteeId={voteeId}
            typingUsers={typingUsers}
            infoContext="sidebar"
          />
        }

        {/* </IonCard> */}
                                </IonItem>
                        </IonList>


                    </IonContent>
                </IonMenu>

                <div className="ion-page" id="main-content">
                  <Header onLogout={handleLogout}>
                    <div className="content-title">
                      {quiz?.title}
                      {/* <sup><span style={{fontSize: '1rem', justifySelf: 'flex-end'}}>
                        {timeElapsed >= voteEndDuration - voteWarningDuration &&
                          `Attention, il vous reste ${voteWarningDuration - (timeElapsed - (voteEndDuration - voteWarningDuration))} secondes pour voter !` ||
                          timeElapsed}
                      </span></sup> */}
                    </div>
                    <div className="content-countdown">
                      {(quiz && voteeId && (!stopVoting || (isVoter && !hasVotedForAllQuestions))) &&
                        <VoteCountdown
                          isVoter={isVoter}
                          hasVotedForAllQuestions={hasVotedForAllQuestions}
                          voteeId={voteeId}
                          voteStartTime={quiz.startTime}
                        />
                      }
                    </div>
                  </Header>

                  <IonContent ref={contentRef} className="ion-padding">
                    {children}
                  </IonContent>
                </div>
            </IonSplitPane>
        </IonPage>
    );
}

export default Page;
