import { useSelector } from 'react-redux';

import { 
  IonReorder,
  IonAccordion, 
  IonItem, 
  IonLabel,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonListHeader,
  IonCardContent,
  IonButton,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { caretDownCircle, caretForward, caretBack } from 'ionicons/icons';
import './UserInfo.css';

import { RootState, User, Quiz, Question, IconType, InfoContext } from '../app/utilities/types';
import { VoteIcon } from './VoteIcons';
import { Votes } from './Votes';

// type UserInfoProps = Omit<Optional<SidebarProps, 'groups'> & {user: User}, 'users'>;

interface UserInfoProps {
  className: string,
  key: string,
  user: User,
  quiz: Quiz | null,
  currentUser: User | null,
  voteeId?: string,
  typingUsers: string[],
  infoContext: InfoContext,
}
  
export const UserInfo: React.FC<UserInfoProps> = ({ user, quiz, currentUser, voteeId, typingUsers, infoContext }) => {
  const {users/*, typingUsers*/} = useSelector(
    (state: RootState) => state.usersState
  );

  const {groups} = useSelector(
    (state: RootState) => state.groupsState
  );

  // TODO DRY WITH GroupInfo.tsx
  let lastTimeVoted = 0;
  let percentageQuestionsVotedFor = 0;
  if (quiz) {
    //! let numQuestions = 0;
    const numQuestionsVotedFor = quiz.questions.reduce((acc, question) => {
      const userAnswer = question.answers.find(answer => answer.userId === user.id);
      // Note answer could have no choice but just be flagged as abstained
      if (userAnswer) {
        ++acc; //! acc += usersAnswer.choice ? 1 : 0;
        if (userAnswer.createdAt && userAnswer.createdAt > lastTimeVoted) {
          lastTimeVoted = userAnswer.createdAt;
        }
      }
      //! ++numQuestions;
      return acc;
    }, 0);
    //! console.log(`numQuestions=${numQuestions} numQuestionsVotedFor=${numQuestionsVotedFor}`);
    if (numQuestionsVotedFor) {
      percentageQuestionsVotedFor = (numQuestionsVotedFor / quiz.questions.length) * 100;
    }
  }

  //! NEEDED FOR ADMINS ONLY
  const userVotes = (user: User, question: Question) => {
    //! const userAnswers = question.answers.filter(answer => answer.userId === user.id);
    //! return userAnswers.reduce((totalVotes, answer) => totalVotes += answer.choice, 0) / (userAnswers.length || 1);
    const answer = question.answers.find(answer => answer.userId === user.id);
    return answer?.choice ?? 0;
  };

  if (infoContext !== "sidebar" && infoContext !== "lobby") {
    return (
      <span
        className="user-info mx-6 my-2 YYXXtext-white XXtext-opacity-70 XXbg-blue-dark XXrounded-md"
      >
        <span>{user.name}</span>
      </span>
    );
  }

  function formatLastTimeVoted() {
    if (quiz) {
      const relTime = Math.floor((lastTimeVoted - quiz.startTime) / 1000);
      const mins = Math.floor(relTime / 60);
      const secs = relTime % 60;
      return `${mins ? mins : ''} ${secs < 10 && mins ? '0' : ''}${secs}s`;
    }
    return "";
  }

  const isVotee = user.id === voteeId;


  const userInfoContent = <>
  {/* <IonItem slot="header" color="rose"> */}
  <IonItem
    slot="header"
    className={isVotee ? 'is-votee' : ''}
    color={isVotee ? 'light' : ''}
  >

  <div
    className={`user-info ${user.online ? 'user-online' : ''} ${user.removed ? 'user-removed' : ''} mx-6 my-2 text-white text-opacity-70 bg-blue-dark rounded-md`}
    data-progress={percentageQuestionsVotedFor}
  >
        <div slot="content" className="progress-container XXprogress-translucent" style={{'--progress': percentageQuestionsVotedFor + "%"} as React.CSSProperties}>
          {/* UNCOMMENT THIS AND ADD progress-translucent CLASS TO PARENT FOR STRIPEY PROGRESS BAR (ACTUALLY, THIS CLASS DOESN'T SEEM TO BE NOW NECESSARY) */}
          {/* <div className="progress-bar">
            <div className="progress-bar-bg"></div>
          </div> */}
          <div className="XXflex XXjustify-between XXitems-center">
            <div className="XXjustify-between XXitems-center" style={{display: 'flex', width: '100%'}}>
              <div className={`h-2 w-2 mr-2 rounded-full inline-block ${user.online ? 'bg-green-400' : 'bg-blue-light'}`}></div>
              <span style={{flex: 1}}>
                {user.name}
  {infoContext !== "lobby" && <>
                {user.id === currentUser!.id &&
                  <IonBadge style={{fontSize: '0.6rem', marginLeft: 5}} color="tertiary">vous</IonBadge>
                }
  </>}
              </span>
              {percentageQuestionsVotedFor === 100 &&
                <IonBadge style={{flex: 'none', fontSize: '0.6rem', marginLeft: 5}} color="light">{formatLastTimeVoted()}</IonBadge>
              }
              {/* {typingUsers.find(name => user.name === name) && (
                <span className="ml-1 text-white text-opacity-50 italic"> typing...</span>
              )} */}
            </div>

            {/* TODO */}
            {/* <IonItem slot="end">
            <VoteIcon
              className="finished-voting-icon"
              iconType={"checkCircleFill" as IconType}
              isNonRatingIconHack={true}
              size={22}
            />
            </IonItem> */}
          </div>
        </div>
  </div>

  {/* Could be used to force next votee */}
  {/* {currentUser!.role === "admin" &&
    <IonButton slot="end">
      <IonIcon icon={user.wasVotee ? caretBack : caretForward} size="small" color="light" slot="" title="Prochain votee"></IonIcon>
    </IonButton>
  } */}

  {/* <IonReorder slot="end"></IonReorder> */}
  <IonReorder slot=""></IonReorder>

  </IonItem>


  <div className="ion-padding" slot="content">

          {currentUser!.role === "admin" && <div className="admin-user-info">
            {/* <div className="ml-1 text-white text-opacity-30">[{user.id}]</div> */}

            {/* <div className="flex flex-col mt-1">
              {(quiz?.questions || []).map((question) => (
                <div key={question.id} className="px-4">
                  <div className="flex flex-col">
                    <div style={{fontSize: '1rem'}}>{question.title}</div>{' '}
                    <div style={{fontSize: '2rem'}}>
                      {Array(question?.maxVotes).fill(null).map((_, i) => (
                        <span key={`vote-icon-${i}`} style={{color: i < userVotes(user, question) ? 'gold' : 'grey'}}>*</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

  {/* TODO COPIED (AND ADAPTED/SIMPLIFIED) FROM Votes.tsx - DRY? */}
  {infoContext !== "lobby" && true &&
            <IonList>
              {quiz?.questions.map((question, i) => (
                <IonCard
                  key={question.title}
                  className="question-container show"
                  style={{'--delay-factor': i} as React.CSSProperties}
                >
                  {/* <IonCardHeader>
                    <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
                    <IonCardTitle>Card Title</IonCardTitle>
                  </IonCardHeader> */}

                  <IonCardContent
                    // className="progress-container progress-container--line"
                    // style={{'--progress': percentageVotersVotedFor[question.id] + "%"} as React.CSSProperties}
                  >
                    <div className="question">
                      <div className="question-title">{question.title}</div>
                      <Votes
                        question={question}
                        questionIndex={i}
                        localUser={user!}
                        // isVoting={isPlaying && (!isVotee || canVoteForSelf) && !stopVoting}
                        // So doesn't show averages
                        isVoting={true || user.isPlaying}
                        infoContext="sidebar"
                        users={users}
                        groups={groups}
                      />
                    </div>
                  </IonCardContent>
                </IonCard>
              ))}
            </IonList>
  }
          </div>}

  </div>
</>;

  if (infoContext === "lobby") {
    // WHY ISN'T THE CLASSNAME SET IN THE PARENT COMPONENT???
    return <div
      className={`user-info entity-info info-context-${infoContext}`}
    >
      {userInfoContent}
    </div>;
  }

  return (

//!    style={{background: `linear-gradient(90deg, var(--sidebar-bg-lighter) 0%, var(--sidebar-bg-lighter) ${percentageQuestionsVotedFor}%, var(--sidebar-bg) ${percentageQuestionsVotedFor}%)`}}
    // <div className={`user-info ${user.online ? 'user-online' : ''} ${user.removed ? 'user-removed' : ''} mx-6 my-2 text-white text-opacity-70 bg-blue-dark rounded-md`}
    //   data-progress={percentageQuestionsVotedFor}>
    //   <div className="progress-container progress-translucent" style={{'--progress': percentageQuestionsVotedFor + "%"} as React.CSSProperties}>
    //     <div className="progress-bar">
    //       <div className="progress-bar-bg"></div>
    //     </div>

    <IonAccordion value={user.id} toggleIcon={caretDownCircle} toggleIconSlot="start" className={`ion-accordion-toggle-icon user-info-container ${isVotee ? 'is-votee' : ''}`}>
        {userInfoContent}
    </IonAccordion>

    //   </div>
    // </div>

  );
};
