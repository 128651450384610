// TODO This should maybe be defined here and imported in the (shared) utilities/types.ts files (currently duplicated for server & client!!)
import { IconType } from '../app/utilities/types';

export interface Icon {
    colour: string,
    fadedColour: string,
    saturatedColour?: string,
    svgPath: string | string[],
}

// Ref: https://icons.getbootstrap.com/
export const icons: Record<IconType, Icon> = {
    star: {
        colour: "#efc900",
        fadedColour: "#dbdbdb",
        saturatedColour: "#efef3f",
        svgPath: "M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z",
    },
    heart: {
        colour: "#ff0000",
        fadedColour: "#eecccc",
        saturatedColour: "#ff5f5f",
        //svgPath: "M316.722,29.761c66.852,0,121.053,54.202,121.053,121.041c0,110.478-218.893,257.212-218.893,257.212S0,266.569,0,150.801 C0,67.584,54.202,29.761,121.041,29.761c40.262,0,75.827,19.745,97.841,49.976C240.899,49.506,276.47,29.761,316.722,29.761z",
        svgPath: "M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z",
        //<path fill-rule="evenodd" d=/>
    },
    like: {
        colour: "#5454ff",
        fadedColour: "#cfcffa",
        saturatedColour: "#9090ff",
        svgPath: "M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z",
    },
    sun: {
        colour: "#f2c100",
        fadedColour: "#f1dd98",
        saturatedColour: "#efef3f",
        svgPath: "M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z",
    },
    sunglasses: {
        colour: "#f2b100",
        fadedColour: "#f1dd98",
        saturatedColour: "#ffdf4f",
        svgPath: [
            "M4.968 9.75a.5.5 0 1 0-.866.5A4.498 4.498 0 0 0 8 12.5a4.5 4.5 0 0 0 3.898-2.25.5.5 0 1 0-.866-.5A3.498 3.498 0 0 1 8 11.5a3.498 3.498 0 0 1-3.032-1.75zM7 5.116V5a1 1 0 0 0-1-1H3.28a1 1 0 0 0-.97 1.243l.311 1.242A2 2 0 0 0 4.561 8H5a2 2 0 0 0 1.994-1.839A2.99 2.99 0 0 1 8 6c.393 0 .74.064 1.006.161A2 2 0 0 0 11 8h.438a2 2 0 0 0 1.94-1.515l.311-1.242A1 1 0 0 0 12.72 4H10a1 1 0 0 0-1 1v.116A4.22 4.22 0 0 0 8 5c-.35 0-.69.04-1 .116z",
            "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-1 0A7 7 0 1 0 1 8a7 7 0 0 0 14 0z",
        ],
    },
    checkCircle: {
        colour: "#ffffff",
        fadedColour: "#eeeeee",
        svgPath: [
            "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
            "M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z",
        ],
    },
    checkCircleFill: {
        colour: "#ffffff",
        fadedColour: "#eeeeee",
        svgPath: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z",
    },
    erase: {
        colour: "#e5566f",
        fadedColour: "#f7c5cf",
        svgPath: "M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z",
    },
    crossCircle: {
        colour: "#8c8c8c",
        fadedColour: "#ccc",
        svgPath: [
            "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
        ],
    },
    bin: {
        colour: "#ffffff",
        fadedColour: "#eeeeee",
        svgPath: [
            "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z",
            // fill-rule="evenodd"
            "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z",
        ],
    },
    snowflake1: {
        colour: "#39a7a7",
        fadedColour: "#bedddd",
        svgPath: "M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z",
    },
    snowflake2: {
        colour: "#39a7a7",
        fadedColour: "#bedddd",
        svgPath: "M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793v-1.086l-.646.647a.5.5 0 0 1-.707-.708L7.5 10.293V8.866l-1.236.713-.495 1.85a.5.5 0 1 1-.966-.26l.237-.882-.94.542-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495.94-.542-.882-.237a.5.5 0 1 1 .258-.966l1.85.495L7 8l-1.236-.713-1.849.495a.5.5 0 1 1-.258-.966l.883-.237-.94-.542-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 0 1 .966-.258l.495 1.849.94.542-.236-.883a.5.5 0 0 1 .966-.258l.495 1.849 1.236.713V5.707L6.147 4.354a.5.5 0 1 1 .707-.708l.646.647V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 0 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v1.086l.647-.647a.5.5 0 1 1 .707.708L8.5 5.707v1.427l1.236-.713.495-1.85a.5.5 0 1 1 .966.26l-.236.882.94-.542.495-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495-.94.542.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l1.236.713 1.849-.495a.5.5 0 0 1 .259.966l-.883.237.94.542 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-.94-.542.236.883a.5.5 0 0 1-.966.258L9.736 9.58 8.5 8.866v1.427l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647v1.086l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z",
    },
    snowflake3: {
        colour: "#39a7a7",
        fadedColour: "#bedddd",
        svgPath: [
            "M8 7.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z",
            "M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793v-1.51l-2.053-1.232-1.348.778-.495 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.883-.237a.5.5 0 1 1 .258-.966l1.85.495L5 9.155v-2.31l-1.4-.808-1.85.495a.5.5 0 1 1-.259-.966l.884-.237-1.12-.646a.5.5 0 0 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849 1.348.778L7.5 4.717v-1.51L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 0 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v1.51l2.053 1.232 1.348-.778.495-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495-1.4.808v2.31l1.4.808 1.849-.495a.5.5 0 1 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-1.348-.778L8.5 11.283v1.51l1.354 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5zm2-6.783V6.783l-2-1.2-2 1.2v2.434l2 1.2 2-1.2z",
        ],
    },
};

/**
 * This function is necessary as the database could contain icon types that do not exist on the
 * client, even though the type system will protect the code against this.
 *
 * @param iconType 
 * @param defaultIconType 
 * @returns Icon of specified type of a default one if does not exist in the icon table.
 */
export function getIcon(iconType: IconType, defaultIconType: IconType = "star"): Icon {
    return icons[iconType] || icons[defaultIconType];
}

export interface VoteIconProps {
    id?: string,
    iconType: IconType,
    size?: number,
    fillPercent?: number,
    colour?: string,
    outline?: boolean,
    className?: string,
    onClick?: any,

    // TODO TEMP FOR NON RATING ICONS (ERASER/ABSTAIN ICONS ETC)
    isNonRatingIconHack?: boolean,
}

//! export const VoteIcon: React.FC<VoteIconProps> = ({iconType, className}) => (
export const VoteIcon: React.FC<VoteIconProps> = (props) => {
const id = props.id ?? ''; //TODO TEMP!
const icon = getIcon(props.iconType);
const size = props.size ?? 32;
const fillPercent = props.fillPercent ?? 1;
const colour = props.colour ?? "currentColor";
const outline = props.outline ?? false;
const className = props.className ?? "";
const onClick = props?.onClick;

if (props.isNonRatingIconHack) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            height={size}
            width={size}
            fill={outline ? "transparent" : colour}
            stroke={colour}
            style={{display: 'inline', margin: '0 3px'}}
            className={className}
            onClick={onClick}
        >
            {/*Array(voteIcons[iconType].svgPath).flatMap(svgPath => <path d={svgPath as string} />)*/}
            {typeof icon.svgPath === 'object'
                ? (icon.svgPath as string[]).map((svgPath, i) => <path key={`path-${i}`} d={svgPath} />)
                : <path d={icon.svgPath as string} />
            }
        </svg>
    );
}

return <>

{true &&
    <div className={`rr--box rr--hf-svg-${outline ? 'on' : 'off'} icon-type--${props.iconType}`}
         style={{'--fill-percent': `${fillPercent}%`} as React.CSSProperties}
    >
    {/* <div className={`rr--box rr--hf-box-int`}> */}

        {/* <svg stroke-width="40" aria-hidden="true" className="rr--svg" xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 518.53 518.53" preserveAspectRatio="xMidYMid meet"> */}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            height={size}
            width={size}
            // Now determined by CSS custom properties
            // fill={outline ? "transparent" : colour}
            // stroke={colour}
            // style={{display: 'inline', margin: '0 3px'}}
            style={{display: 'inline'}}
            className={`rr--svg ${className}`}
            onClick={onClick}
        >
            <defs>
                <linearGradient id={`${id}--def`}>
                    <stop className="rr--svg-stop-1" offset={`${fillPercent}%`}></stop>
                    <stop className="rr--svg-stop-2" offset={`${fillPercent}%`}></stop>
                </linearGradient>
            </defs>
            <g fill={`url('#${id}--def')`}>

                {/*Array(voteIcons[iconType].svgPath).flatMap(svgPath => <path d={svgPath as string} />)*/}
                {typeof icon.svgPath === 'object'
                    ? (icon.svgPath as string[]).map((svgPath, i) => <path key={`path-${i}`} d={svgPath} />)
                    : <path d={icon.svgPath as string} />
                }
            </g>
        </svg>

    </div>
}

{/* Boxed icons */}
{false &&
    <div className="rr--box rr--hf-box-int"
         style={{'--fill-percent': `${fillPercent}%`} as React.CSSProperties}
    >
{/* <svg stroke-width="40" aria-hidden="true" className="rr--svg" xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 518.53 518.53" preserveAspectRatio="xMidYMid meet"> */}
        <svg
viewBox="-20 -20 518.53 518.53"
// preserveAspectRatio="xMidYMid meet"
// stroke-width="40" 
            xmlns="http://www.w3.org/2000/svg"
            // viewBox="0 0 16 16"
            height={size}
            width={size}
            // fill={outline ? "transparent" : colour}
            // stroke={colour}
            // style={{display: 'inline', margin: '0 3px'}}
            style={{display: 'inline'}}
            className={`rr--svg ${className}`}
            onClick={onClick}
        >
            <g>
                <polygon points="478.53 189 318.53 152.69 239.26 0 160 152.69 0 189 111.02 303.45 84 478.53 239.26 396.63 394.53 478.53 367.51 303.45 478.53 189"></polygon>
            </g>
        </svg>
    </div>
}

</>
};

export const inlineSvg = (args: any): string => {
const iconType = args.iconType as IconType;
const icon = getIcon(iconType);
const size = args.size ?? 32;
const colour = args.colour ?? "currentColor";
const className = args.className ?? "";
return (
    `<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        height="${size}"
        width="${size}"
        fill="${colour}"
        style="display: inline, margin: 0 3px"
        class="${className}"
    >
        ${typeof icon.svgPath === 'object'
            ? (icon.svgPath as string[]).map((svgPath, i) => `<path key="${`path-${i}`}" d="${svgPath}" />`)
            : `<path d="${icon.svgPath as string}" />`
        }
    </svg>`.replaceAll("\n", "")
);
};
